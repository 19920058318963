import React, { useEffect, useState } from "react";
import {
  fetcherWithToken,
  postWithToken,
  updateWithToken,
} from "../../hooks/use-fetch-data";
import { QuestionI, Time } from "./types";
import { useKatexContext, useUserContext } from "../../Authenticator";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Header,
  Body,
  RightRail,
  BodyContainer,
  BodyHeader,
  QuestionBox,
  QuestionHtml,
  Question,
  Answer,
  X,
  SaveLater,
  Bottom,
  AroundTooltip,
  UntimedMode,
  AnswerHtml,
  EachAnswer,
  FinishButton,
  MathButtons,
} from "./styles";
import { Button } from "components/Button";
import { Text } from "components/Text";
import { theme } from "components/theme/theme";
import useSWR from "swr";
import { useTimer } from "hooks/timer";
import { Tooltip } from "react-tooltip";
import { FaChevronUp } from "react-icons/fa";
import { BsBookmarkFill } from "react-icons/bs";
import { QuestionToolTip } from "./QuestionsTooltip";
import toast from "react-hot-toast";
import { MdOutlineTimerOff } from "react-icons/md";
import { FaRegCheckCircle } from "react-icons/fa";
import { DivComponent, Dropdown, Modal } from "components";
import { IoIosCalculator } from "react-icons/io";
import { PiMathOperationsLight } from "react-icons/pi";
import { CiSaveDown2 } from "react-icons/ci";
import refImg from "../../assets/images/reference.jpg";
import MathSkills from "components/MathDirections";

declare global {
  interface Window {
    renderMathInElement: any;
  }
}

const Test: React.FC = () => {
  const userCtx = useUserContext();
  const navigate = useNavigate();
  const params = useParams();

  const { renderMath } = useKatexContext();

  const [selected, setSelected] = useState<number | null>();
  const [crossed, setCrossed] = useState<number[]>([]);
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const [writeIn, setWriteIn] = useState<string>();
  const [finishedModal, setFinishedModal] = useState(false);
  const [mathDirectionsModal, setMathDirectionsModal] = useState(false);
  const [startTime, setStartTime] = useState<Time>({ minutes: 0, seconds: 0 });

  const openRefImg = () => {
    const windowFeatures = "width=800,height=600"; // Set the window size and other features
    window.open(refImg, "_blank", windowFeatures);
  };

  const { data, mutate, isLoading } = useSWR<QuestionI>(
    `/api/v1/auth/class/${params.classID}/test/${params.testID}/module/${params.moduleId}/question/${params.questionNumber}`,
    (url: string) => fetcherWithToken<QuestionI>(url, userCtx.token),
    {
      revalidateOnMount: true,
    }
  );

  const saveAndExit = async (module?: number) => {
    await saveTime();
    await updateWithToken(
      `/api/v1/auth/class/${params.classID}/test/${params.testID}/save-progress`,
      userCtx.token,
      {
        testID: Number(params.testID),
        moduleID: module || Number(params.moduleId),
        questionNumber: module ? 0 : Number(params.questionNumber),
        classID: Number(params.classID),
      }
    );

    if (module) {
      return;
    }

    navigate("/");
    toast.success("Save and exited success", {
      position: "bottom-left",
      icon: <FaRegCheckCircle size={18} color="#154471" />,
    });
  };

  const moduleTimeUp = async () => {
    await saveTime();

    await updateWithToken(
      `/api/v1/auth/class/${params.classID}/test/${params.testID}/module/${params.moduleID}/module-finished`,
      userCtx.token,
      {
        moduleID: Number(params.moduleId),
        classID: Number(params.classID),
      }
    );

    nextSection();
  };

  const { minutes, seconds, start, hasStarted } = useTimer({
    onFinish: moduleTimeUp,
  });

  const timeDiff = (start: Time, end: Time) => {
    // Convert start time and end time to total seconds
    const startInSeconds = start.minutes * 60 + start.seconds;
    const endInSeconds = end.minutes * 60 + end.seconds;

    // Return the absolute difference in seconds
    return Math.abs(startInSeconds - endInSeconds);
  };

  const saveTime = async () => {
    await updateWithToken(
      `/api/v1/auth/class/${params.classID}/test/${params.testID}/module/${params.moduleId}/save-time`,
      userCtx.token,
      {
        timeLeft: minutes * 60 + seconds,
        timeSpent: timeDiff(startTime, { minutes, seconds }),
        questionID: data?.question.QuestionID,
      }
    );
  };

  useEffect(() => {
    // Attach the event listener
    window.addEventListener("beforeunload", saveTime);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("beforeunload", saveTime);
    };
  }, []);

  useEffect(() => {
    if (hasStarted) {
      setStartTime({ minutes, seconds });
    }
  }, [hasStarted]);

  useEffect(() => {
    setFinishedModal(false);

    renderMath?.();
    if (data) {
      setSelected(data.userAnswer?.AnswerChoiceID);
      setWriteIn(data.userAnswer?.WriteInAnswer);
    }

    if (data?.userModule.TimeLeft && data.userModule.UntimedMode === false) {
      if (!hasStarted) {
        start(data.userModule.TimeLeft);
      }
    }

    if (data?.userModule.Finished) {
      alert("You cannot go back to a module after you finished!");

      window.location.href = `/class/${params.classID}/test/${params.testID}/module/${data?.nextModule?.ModuleID}`;
    }
  }, [data]);

  const isLastQuestion =
    data?.allQuestions.length === data?.question.QuestionNumber;

  const cross = (aId: number) => {
    if (crossed.includes(aId)) {
      const removed = crossed.filter((i) => i !== aId);

      setCrossed(removed);
    } else {
      setCrossed([...crossed, aId]);
    }
  };

  const nextQuestion = async () => {
    await saveTime();
    setWriteIn(undefined);
    setStartTime({ minutes, seconds });

    navigate(
      `/class/${params.classID}/test/${params.testID}/module/${
        params.moduleId
      }/question/${data?.question && data.question.QuestionNumber + 1}`
    );
  };

  const nextSection = async () => {
    const url = `/api/v1/auth/class/${params.classID}/test/${params.testID}/module/${params.moduleId}/question/${params.questionNumber}`;
    const resp = await fetcherWithToken<QuestionI>(url, userCtx.token);

    if (resp.nextModule) {
      saveAndExit(resp.nextModule.ModuleID);
      navigate(
        `/class/${params.classID}/test/${params.testID}/module/${resp.nextModule.ModuleID}`
      );
    } else {
      navigate(`/class/${params.classID}/test/${params.testID}/report`);
    }
  };

  const updateOrDeleteAnswer = async () => {
    // update answer
    if (data?.userAnswer) {
      const res = await updateWithToken(
        `/api/v1/auth/class/${params.classID}/test/${params.testID}/module/${params.moduleId}/question/${params.questionNumber}`,
        userCtx.token,
        { answer: selected, questionID: data?.question.QuestionID, writeIn }
      );

      if (res.status === 201) {
        toast.success("Saved", {
          position: "bottom-left",
          icon: <FaRegCheckCircle size={18} color="#154471" />,
        });
        return isLastQuestion ? setFinishedModal(true) : nextQuestion();
      }
    }

    setWriteIn(undefined);
    isLastQuestion ? setFinishedModal(true) : nextQuestion();
  };

  const saveForLater = async () => {
    if (data?.userAnswer) {
      await updateWithToken(
        `/api/v1/auth/class/${params.classID}/test/${params.testID}/module/${params.moduleId}/question/${params.questionNumber}`,
        userCtx.token,
        { answer: selected, questionID: data?.question.QuestionID, writeIn }
      );
    }
    const res = await postWithToken(
      `/api/v1/auth/class/${params.classID}/test/${params.testID}/module/${params.moduleId}/save-question-for-later`,
      userCtx.token,
      {
        questionID: data?.question.QuestionID,
        trueOrFalse: !data?.userAnswer?.SaveForLater,
      }
    );

    if (res?.status === 201) {
      mutate(data);

      toast.success(
        data?.userAnswer?.SaveForLater ? "Unsaved" : "Saved for later!",
        {
          position: "bottom-left",
          icon: <FaRegCheckCircle size={18} color="#154471" />,
        }
      );
    }
  };

  const previousQuestion = async () => {
    await saveTime();
    setStartTime({ minutes, seconds });
    navigate(
      `/class/${params.classID}/test/${params.testID}/module/${
        params.moduleId
      }/question/${data?.question && data.question.QuestionNumber - 1}`
    );
  };

  return (
    <>
      <Modal
        open={mathDirectionsModal}
        onClose={() => setMathDirectionsModal(false)}
        medium
      >
        <MathSkills />
      </Modal>
      <AroundTooltip>
        <Tooltip
          id="my-tooltip"
          isOpen={toolTipOpen}
          className="tooltip"
          clickable
        >
          <QuestionToolTip data={data} setClose={() => setToolTipOpen(false)} />
        </Tooltip>
      </AroundTooltip>
      <Modal
        open={finishedModal}
        onClose={() => setFinishedModal(false)}
        medium
      >
        <DivComponent column spaceBetween>
          <Text size={30} center mb={12}>
            Check Your Work
          </Text>
          <Text size={20} mb={12}>
            On test day, you won't be able to move onto the next section until
            the module time expires. For these practice questions, you can click
            Finish when you're ready to move on.
          </Text>
          <Text size={20}>
            {data?.module.Title} | {data?.module.Category}
          </Text>
          <QuestionToolTip
            showClose={false}
            data={data}
            setClose={() => setToolTipOpen(false)}
          />
          <FinishButton text="Finish" onClick={() => moduleTimeUp()} />
        </DivComponent>
      </Modal>
      <Container>
        <DivComponent column>
          <Header>
            <DivComponent padding="24px 24px" column>
              <Text size={24} bold color={theme.colors.secondary1}>
                {data?.module.Title}
              </Text>
              {data?.module.Category === "math" && (
                <Text
                  size={14}
                  onClick={() => setMathDirectionsModal(true)}
                  underline
                  hover
                  color={theme.colors.secondary1}
                >
                  Directions
                </Text>
              )}
            </DivComponent>
            {data?.module.Category === "math" && (
              <MathButtons>
                <DivComponent column alignItems onClick={openRefImg}>
                  <PiMathOperationsLight size={50} color={"white"} />
                  <Text size={10} color="white">
                    Reference
                  </Text>
                </DivComponent>
                <DivComponent column alignItems>
                  <a
                    href="https://www.desmos.com/calculator"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IoIosCalculator size={50} color={"white"} />
                    <Text size={10} color="white">
                      Calculator
                    </Text>
                  </a>
                </DivComponent>
              </MathButtons>
            )}
            <DivComponent padding="24px 24px" alignItems goRight>
              {data?.userModule.UntimedMode ? (
                <UntimedMode>
                  <Text size={18} color="white" mr={12}>
                    Untimed Mode
                  </Text>
                  <MdOutlineTimerOff size={24} />
                </UntimedMode>
              ) : (
                <Text color="white" size={18} center>
                  {minutes} mins : {seconds} sec
                </Text>
              )}
            </DivComponent>
            <DivComponent column alignItems mr={24}>
              <Dropdown
                items={[
                  <DivComponent justifyContent onClick={() => saveAndExit()}>
                    <CiSaveDown2 color="black" size={24} />
                    <Text ml={12} size={18}>
                      Save and Exit
                    </Text>
                  </DivComponent>,
                ]}
              />
              <Text size={10} color="white">
                More
              </Text>
            </DivComponent>
          </Header>
          <BodyContainer>
            <Body>
              <BodyHeader>
                {data?.module.Category === "english" && (
                  <QuestionHtml
                    dangerouslySetInnerHTML={{
                      __html: data?.question.Passage || "",
                    }}
                  ></QuestionHtml>
                )}
                {data?.module.Category === "math" && (
                  <QuestionHtml
                    data-testid="react-katex"
                    dangerouslySetInnerHTML={{
                      __html: data?.question.Question || "",
                    }}
                  ></QuestionHtml>
                )}
              </BodyHeader>
            </Body>
            <RightRail>
              <DivComponent>
                {data?.module && (
                  <p style={{ fontSize: 20, marginRight: 5 }}>
                    {data?.question.QuestionNumber})
                  </p>
                )}
                {data?.module.Category === "english" && (
                  <QuestionHtml
                    dangerouslySetInnerHTML={{
                      __html: data?.question.Question || "",
                    }}
                  ></QuestionHtml>
                )}
              </DivComponent>
              <QuestionBox>
                {data?.question?.AnswerChoices.map((a, i) => {
                  if (data.question.Type === "write_in") {
                    return (
                      <EachAnswer>
                        <input
                          type="text"
                          value={writeIn}
                          placeholder="Write answer here"
                          name="write_in"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setWriteIn(e.target.value)
                          }
                        />
                      </EachAnswer>
                    );
                  }
                  return (
                    <EachAnswer key={a.AnswerChoiceID + a.ChoiceText}>
                      <span onClick={() => cross(a.AnswerChoiceID)}>
                        <X size={18}>&#x2715;</X>
                      </span>
                      <Answer
                        crossed={crossed?.includes(a.AnswerChoiceID)}
                        onClick={() => {
                          a.AnswerChoiceID === selected
                            ? setSelected(null)
                            : setSelected(a.AnswerChoiceID);
                        }}
                        selected={selected === a.AnswerChoiceID}
                      >
                        {i === 0 && "A)  "}
                        {i === 1 && "B)  "}
                        {i === 2 && "C)  "}
                        {i === 3 && "D)  "}
                        <AnswerHtml
                          dangerouslySetInnerHTML={{
                            __html: a.ChoiceText || "",
                          }}
                        ></AnswerHtml>
                      </Answer>
                    </EachAnswer>
                  );
                })}
                <SaveLater onClick={() => saveForLater()}>
                  <BsBookmarkFill
                    color={data?.userAnswer?.SaveForLater ? "red" : "black"}
                  />
                  Save for Review
                </SaveLater>
              </QuestionBox>
            </RightRail>
          </BodyContainer>
        </DivComponent>
        <Bottom>
          <Button
            invisible={data?.question.QuestionNumber === 1}
            color={theme.colors.primary1}
            onClick={previousQuestion}
            text={"Prev. Question"}
          />
          <Question
            data-tooltip-id="my-tooltip"
            onMouseEnter={() => setToolTipOpen(true)}
          >
            Question {params.questionNumber} of{" "}
            {data?.allQuestions[data.allQuestions.length - 1].QuestionNumber}
            <FaChevronUp />
          </Question>
          <Button
            onClick={updateOrDeleteAnswer}
            disabled={isLoading}
            text={
              isLoading
                ? "Next Question"
                : isLastQuestion
                  ? "Finish Module"
                  : "Next Question"
            }
            color={theme.colors.primary1}
          />
        </Bottom>
      </Container>
    </>
  );
};

export default Test;
