import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.primary2};
  color: ${({ theme }) => theme.colors.secondary1};
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
`;

const Address = styled.div`
  font-size: 1rem;
`;

const Hours = styled.div`
  font-size: 0.9rem;
  line-height: 1.5;
`;

const Phone = styled.div`
  font-size: 1rem;
  font-weight: bold;
`;

const Saying = styled.div`
  margin-top: 20px;
  font-size: 1.2rem;
  font-style: italic;
  color: ${({ theme }) => theme.colors.primary1};
`;

const SocialMedia = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 15px;

  a {
    color: ${({ theme }) => theme.colors.secondary1};
    font-size: 1.5rem;
    transition: color 0.3s;

    &:hover {
      color: ${({ theme }) => theme.colors.primary1};
    }
  }
`;

const Footer = () => {
  return (
      <FooterContainer>
        <Address>
          Address: 310 Old Country Rd #101, Garden City, NY 11530
        </Address>
        <Hours>
          Hours:<br />
          Sunday: 9 AM–5 PM<br />
          Monday–Friday: 10 AM–8 PM<br />
          Saturday: 9 AM–5 PM
        </Hours>
        <Phone>Phone: (516) 728-1561</Phone>
        <Saying>Students are more than just a score. We're here to help students achieve their academic goals.</Saying>
        <SocialMedia>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <FaFacebook />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <FaInstagram />
          </a>
        </SocialMedia>
      </FooterContainer>
  );
};

export default Footer;

